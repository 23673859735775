<template>
	<div class="newList">
		<tap :taps="taps" :isMore="false" :tapsTrue="true" :tapIndex="tapIndex"  @clkTap="clkTap"></tap>
		<div class="list-box">
			<div class="list-item" v-for="item in news.data" :key="item.id" @click="clkNews(item.id)">
				<div style="overflow: hidden;">
					<img :src="item.thumb" alt="" >
				</div>
				
				<div class="title">{{item.title}}</div>
				<div class="row">
					<div class="time">{{item.inputtime}}</div>
					<i class="iconfont">&#xe642;</i>
				</div>
			</div>
		</div>
		<div class="block">
		    <el-pagination
				background
				layout="total, prev, pager, next, jumper"
				:total="news.total"
				:page-size="news.per_page"
				@current-change="curChge">
		    </el-pagination>
		</div>
	</div>
</template>

<script>
	import qs from "qs"
	export default{
		data() {
			return {
				page:1,
				news:{}
			}
		},
		created() {
			this.getNews()
			//新闻列表
			this.LeftSidebar(147)
		},
		
		methods:{
			clkTap(index,id) {
				this.tapIndex = index
				this.getNews(id)
			},
			getNews(catid) {
				let url = this.$api.about.news_center2
				let data = {
					catid,
					page:this.page,
					limit:9
				}
				this.$axios.post(url,qs.stringify(data)).then(res=>{
					if(res.code) {
						this.news = res.data
						console.log('this.news', this.news)
					}
				})
			},
			//左边导航栏
			LeftSidebar(nav_id) {
				let url = this.$api.about.LeftSidebar
				let data = {
					nav_id
				}
				this.$axios.post(url, qs.stringify(data)).then(res => {
					if (res.code) {
						this.taps = res.list
						console.log('this.taps', this.taps)
					}
				})
			},
			curChge(e) {
				this.page = e
				this.getNews()
			},
			clkNews(id) {
				let index = this.$route.query.index
				console.log(index)
				this.$router.push({name:"newdetail",query:{index,id}})
			}
		}
	}
</script>

<style lang="less" scoped>
	.list-box {
		display: grid;
		grid-template-columns: repeat(3,1fr);
		column-gap: 20px;
		row-gap: 30px;
		.list-item {
			transition: all .5s;
			cursor: pointer;
			&:hover {
				box-shadow: 0 0 10px rgba(0,0,0,.3);
				img {
					transform: scale(1.1);
				}
			}
			img {
				width: 100%;
				height: 2.4em;
				object-fit: cover;
				transition: all .5s;
			}
			.title {
				line-height: 27px;
				color: rgba(51, 51, 51, 1);
				font-size: 18px;
				font-weight: 500;
				margin: 16px 0;
				display: -webkit-box;
				overflow: hidden;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
			.row {
				display: flex;
				justify-content: space-between;
				line-height: 21px;
				color: rgba(102, 102, 102, 1);
				font-size: 14px;
				font-weight: 400;
				padding-bottom: 10px;
				.iconfont {
					font-size: 14px;
				}
			}
		}
	}
	
	@media (max-width:1200px) {
		.list-box .list-item .title {
			font-size: 16px;
			margin: 10px 0;
		}
	}
	@media (max-width:1000px) {
		.list-box {
			grid-template-columns: repeat(2,1fr);
		}
	}
	@media (max-width:768px) {
		.list-box .list-item img {
			height: 150px;
		}
	}
</style>